import { AppBar, Slide, Toolbar, useScrollTrigger } from '@mui/material';
import React from 'react';

// copy paste from https://material-ui.com/components/app-bar/#hide-app-bar
function HideOnScroll({
  children,
  window,
}: {
  children: React.ReactElement;
  window?: () => Window;
}) {
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Navbar({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        color="inherit"
        elevation={0}
        className={className}
      >
        <Toolbar>{children}</Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Navbar;
