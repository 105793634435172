/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h3: "h3",
    strong: "strong",
    ul: "ul",
    li: "li",
    em: "em",
    code: "code"
  }, _provideComponents(), props.components), {Boss, Warning, Specialization, Skill, Boon, Instability, Effect, Trait, Condition, Control, SpecialActionKey} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Control) _missingMdxReference("Control", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "MAMA",
    video: "WFYMOt5gf_4",
    videoCreator: "KalzeN [dT]",
    foodId: "43360",
    utilityId: "50082",
    heal: "glyphofelementalharmony",
    utility1: "unravel",
    utility2: "glyphofstorms",
    utility3: "primordialstance",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "WFYMOt5gf_4",
    videoCreator: "KalzeN [dT]",
    foodId: "43360",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "5569",
    utility1Id: "44612",
    utility2Id: "5734",
    utility3Id: "40183",
    eliteId: "5516"
  }, React.createElement(Warning, null, React.createElement(_components.p, null, "This build only works if you kill MAMA in less than 55 seconds. If there is any doubt about the kill time, go with the PuG version. The following guide assumes you are following the proposed ", React.createElement(_components.a, {
    href: "/guides/cc-distribution"
  }, "CC-Distribution"), ".")), React.createElement(_components.p, null, "In more organised groups it's recommended to run ", React.createElement(Specialization, {
    name: "Tempest"
  }), "+", React.createElement(Skill, {
    name: "conjurelightninghammer",
    id: "5624"
  }), " precast to get extra phase 2 DPS and better ", React.createElement(Boon, {
    name: "Quickness"
  }), "/", React.createElement(Boon, {
    name: "Fury"
  }), " uptime after the ", React.createElement(Skill, {
    name: "feelmywrath",
    id: "29965"
  }), " changes (especially if you want to run with ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " instead of ", React.createElement(Specialization, {
    name: "Firebrand"
  }), ").")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Tempest Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When ", React.createElement(Specialization, {
    name: "Berserker"
  }), " is running to precast his banners run with him and precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " behind the boss hitbox"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Go to the boss using portal or ", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " and drop your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " to not get stuck on staff. Cast ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " where MAMA spawns. Swap template/equip your Sword/Dagger and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " before the ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " animation finishes and start the boss!"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " and ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " to hit with it just when boss becomes vulnerable for insta vulnerability and dps increase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If the CC Bar is not broken when you use ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " then cast ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " so that you might get ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " in the break bar."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When boss is just phased precast ", React.createElement(Skill, {
    id: "5736",
    id: "5736"
  }), " on the boss spawn for p2 hits while still being in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " and swap back to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " with remaining ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " where the 2nd knight spawns and use ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " on add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on boss spawn and grab your Lightning Hammer."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for vulnerability."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " and drop Hammer, use autochain if ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " is still on cd."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune directly to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " using ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " and continue to auto attack till MAMA phases again."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), " and pick up ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " on add and precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on boss hitbox."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When the knight is about to die, move towards MAMA and precast ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " and ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for quick cc and vulnerability boost for p3 ( If you re not sure about updraft precast timer just use it asap MAMA becomes vulnerable .)"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " ->", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune directly to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " using ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " and continue to auto attack till MAMA phases again."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "3rd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), " and auto attack on ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " till the knight is about to die."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " when MAMA is about to jump to damage both knight and boss when knight is dead."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "4th Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Dodge inside the boss hitbox when she lands, then stand behind her to evade the two spins of her double spin slam attack and start your burst; you do not have to avoid the spins if the cc is good."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " when boss is about to land."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " ->", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " ->", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune directly to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " using ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " and continue to auto attack till MAMA is dead."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "NOTE: If you're fast and Boss is in range when she s going to jump you can use ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> dodge -> and then rest of combo."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "siax",
    video: "toqlXSTRSVg",
    videoCreator: "KalzeN [dT]",
    foodId: "43360",
    utilityId: "50082",
    heal: "signetofrestoration",
    utility1: "unravel",
    utility2: "glyphofstorms",
    utility3: "primordialstance",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "serpentslaying",
    weapon1OffInfusionId: "37131",
    video: "toqlXSTRSVg",
    videoCreator: "KalzeN [dT]",
    foodId: "43360",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24658",
    healId: "5503",
    utility1Id: "44612",
    utility2Id: "5734",
    utility3Id: "40183",
    eliteId: "5516"
  }, React.createElement(Warning, null, React.createElement(_components.p, null, "This build only works if you kill Siax in less than 55 seconds. If there is any doubt about the kill time, go with the PuG version.")), React.createElement(_components.p, null, "In more organised groups it's recommended to run ", React.createElement(Specialization, {
    name: "Tempest"
  }), "+", React.createElement(Skill, {
    name: "conjurelightninghammer",
    id: "5624"
  }), " precast to get extra phase 2 DPS and better ", React.createElement(Boon, {
    name: "Quickness"
  }), "/", React.createElement(Boon, {
    name: "Fury"
  }), " uptime after the ", React.createElement(Skill, {
    name: "feelmywrath",
    id: "29965"
  }), " changes (especially if you want to run with ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " instead of ", React.createElement(Specialization, {
    name: "Firebrand"
  }), ").")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " for yourself and ", React.createElement(Skill, {
    id: "5567",
    id: "5567"
  }), " for ", React.createElement(Specialization, {
    name: "Renegade"
  }), " close to the boss hitbox"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " when boss is pulled"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> Pick up LH -> ", React.createElement(Skill, {
    id: "5733",
    id: "5733"
  }), " for CC -> ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " -> Attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " with remaining ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " uptime."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When boss is at 68-66% throw ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " in the middle of boss hitbox."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Jump at add with your ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " and after damaging it move towards the Siax."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " on boss hitbox"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " If there is ", React.createElement(Instability, {
    name: "Vengeance"
  }), " or ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " and adds have ", React.createElement(Boon, {
    name: "Protection"
  }), " it's recommended to use ", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " from adds back to move towards siax and provide more help for guardian with killing echo. You have to be ", React.createElement(_components.strong, null, "very"), " fast in that phase otherwise you will loose a lot of your phase 2 burst."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "2nd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "It's the most important part for you as ", React.createElement(Specialization, {
    name: "weaver"
  }), ". You have to be as fast as possible to get proper burst in ", React.createElement(Effect, {
    name: "Exposed"
  }), " after changes ( 4s ", React.createElement(Effect, {
    name: "Exposed"
  }), " duration )."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), "->", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), "-> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> auto chain in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " -> Attune back to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " with remaining ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When boss is about 36-33% pick up ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Be careful with tether mechanic to not blow up your allies. If you are fast and have a close eye on others echos it's possible to precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " and use ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " priority to get full ", React.createElement(Effect, {
    name: "Exposed"
  }), " dmg and additional ", React.createElement(Trait, {
    id: "1510",
    id: "1510"
  }), " stacks from tethers, but you have to be insanely fast."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " to add, use few autos from your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and ", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " through the echo back to boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you have troubles with that part you can use 1-2 ticks from ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " to give deal some additional ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " on add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on Siax."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "3rd Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), "-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> auto chain in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> Attune back to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " for additional dmg from ", React.createElement(Trait, {
    id: "222",
    id: "222"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If timers are slow you will be able to skip auto chain in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " for ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " instantly."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "0mmm-CBLrn8",
    videoCreator: "KalzeN [dT]",
    foodId: "41569",
    utilityId: "50082",
    heal: "arcanebrilliance",
    utility1: "arcaneblast",
    utility2: "glyphofstorms",
    utility3: "primordialstance",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "serpentslaying",
    weapon1OffInfusionId: "37131",
    video: "0mmm-CBLrn8",
    videoCreator: "KalzeN [dT]",
    foodId: "41569",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24658",
    healId: "21656",
    utility1Id: "5539",
    utility2Id: "5734",
    utility3Id: "40183",
    eliteId: "5516"
  }, React.createElement(_components.p, null, "Use first ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " precasted for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), ", if phases are long try to use the next ones in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " -> ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " to get more ", React.createElement(Condition, {
    name: "Burning"
  }), " stacks.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " close to the boss hitbox and gg/use portal to get back to party."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " when boss is about to be pulled."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " It is possible use tempest precast as well if any party member is placing fire field."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "1st Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " -> ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " ->", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " ->", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " ->", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "-> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue loop until boss is phased."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Save ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " after exposed buff for next phases."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Splits")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For splits there is some time to blast additional might and be back on ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " for incoming phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place fire field with ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " and attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), ". Blast it with ", React.createElement(Skill, {
    id: "40709",
    id: "40709"
  }), " , attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), " and use another blast with ", React.createElement(Skill, {
    id: "5690",
    id: "5690"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), ", attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " just before p2/p3 to get ", React.createElement(Trait, {
    id: "2131",
    id: "2131"
  }), " buff."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " for ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " inside his/her circle."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Do not hesitate to use ", React.createElement(Skill, {
    id: "40709",
    id: "40709"
  }), ", ", React.createElement(Skill, {
    id: "46447",
    id: "46447"
  }), ", ", React.createElement(Skill, {
    id: "44405",
    id: "44405"
  }), " if things are going hot. Just keep in mind to be on ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " just as phase starts."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Before the boss becomes vulnerable blast ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  }), " with fire field placed by warrior so that party gets some ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " on boss hitbox."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " if it's up just at the start of phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " -> ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), " -> ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> Complete your auto attack chain -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue loop until boss is phased."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Complete auto attack chains and get as much damage as you can in the ", React.createElement(Effect, {
    name: "Exposed"
  }), " duration."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Before the boss becomes vulnerable blast ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  }), " with fire field placed by warrior so that party gets some ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), "-> ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), " ->", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> Autoattack Chain -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue loop with BTTH rotation until boss is phased."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Complete auto attack chains and get as much damage as it's possible in the ", React.createElement(Effect, {
    name: "Exposed"
  }), " duration."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Try to double dodge CCing attack if phase 15% before bubble and don't have enough dps to kill boss before CCing attack."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "DaKI7Ccr_Ss",
    videoCreator: "Tym [dT]",
    foodId: "91805",
    utilityId: "9443",
    heal: "arcanebrilliance",
    utility1: "unravel",
    utility2: "glyphofstorms",
    utility3: "primordialstance",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "DaKI7Ccr_Ss",
    videoCreator: "Tym [dT]",
    foodId: "91805",
    utilityId: "9443",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "21656",
    utility1Id: "44612",
    utility2Id: "5734",
    utility3Id: "40183",
    eliteId: "5516"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Make sure to drop your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " on the 4th platform ASAP so that your ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " can pick it up before taking the Portal"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "In ", React.createElement(_components.strong, null, "VERY"), " fast groups it's not that bad to use ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " instead of ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), ". Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " at the start of each phase, if playing ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " spam it during ", React.createElement(Effect, {
    name: "Exposed"
  }), " window."), "\n"), "\n"), React.createElement(Warning, null, React.createElement(_components.p, null, "One of the most important things in this fight is to keep up ", React.createElement(Boon, {
    name: "Might"
  }), " and boons at the four ", React.createElement(_components.em, null, "Elite Flux Anomalies"), ". To achieve this you need to be fast enough with your damage, hit your blasts correctly and not get hit by the shockwaves and knockbacks."))), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), ", drop your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " and cast ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " where Skorvald spawns. Equip your Sword/Dagger and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " before the ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " animation finishes and start the boss!"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " and pull at the end of cast time."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), " for instant cc, cast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " while coming back to boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " and ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " to hit with it just when boss becomes vulnerable for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and DPS increase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If the CC Bar is not broken when you use ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " then cast ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " so that you might get ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " in the break bar."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Islands")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Stay in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " after phase 1"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " to anomaly, attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), " and blast fire field with ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Auto add until it's dead"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Place fire field with ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), ", both blast and evade ", React.createElement(Control, {
    name: "Knockback"
  }), " wave with ", React.createElement(Skill, {
    id: "40709",
    id: "40709"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " on anomaly just after wave. Attune to ", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), " and blast second time with ", React.createElement(Skill, {
    id: "5690",
    id: "5690"
  })), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5495",
    id: "5495"
  }), " and use ", React.createElement(Skill, {
    id: "46447",
    id: "46447"
  }), " as fast as possible to provide self ", React.createElement(Boon, {
    name: "Stability"
  }), " and don't get knocked"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " on anomaly and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  })), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Island 4")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Drop fire field with ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " as fast as possible and blast it with ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " in corner for your ", React.createElement(Specialization, {
    name: "Soulbeast"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Avoid ", React.createElement(Control, {
    name: "Knockback"
  }), " wave at all cost, you can jump over it, you can dodge it, you can jump over it with ", React.createElement(Skill, {
    id: "5517",
    id: "5517"
  }), " ( but it's tricky ). Just don't get cc'ed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Go through the portal for phase 2 when anomaly is on about 30-40% of health."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " only if it's off cd and you are not using portals between islands for phase 3."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), "->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Islands")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The same rotation as for first round, just without ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " part."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44612",
    id: "44612"
  }), " ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), "."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "Artsariiv",
    video: "DaKI7Ccr_Ss",
    timestamp: "109",
    videoCreator: "Tym [dT]",
    foodId: "91805",
    utilityId: "9443",
    heal: "arcanebrilliance",
    utility1: "primordialstance",
    utility2: "glyphofstorms",
    utility3: "arcaneblast",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "DaKI7Ccr_Ss",
    videoCreator: "Tym [dT]",
    foodId: "91805",
    utilityId: "9443",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "21656",
    utility1Id: "40183",
    utility2Id: "5734",
    utility3Id: "5539",
    eliteId: "5516"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Play ", React.createElement(Trait, {
    name: "ragingstorm",
    id: "214"
  }), " over ", React.createElement(Trait, {
    name: "stormsoul",
    id: "1502"
  }), "!"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " at the start of each phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " while in ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " / ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), ", especially when ", React.createElement(Skill, {
    name: "Onewolfpack",
    id: "45717"
  }), " buff is up."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "/gg"), " if you are doomed ( skull ) and it's impossible to phase before it blows up."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Mid-strategy: the strategy is focused around phasing the boss in the middle before she moves."), "\n"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " behind boss hitbox (on edge of it, slightly left to not get cucked with marbles during picking it up, watch any PoV for more input ). /gg or use portal to get back to ", React.createElement(_components.em, null, "Mistlock Singularity"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " You can use tempest precast as well if any party member is placing fire field."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast on boss")), "\n", React.createElement(_components.p, null, "It's not that easy precast and opener, it's recommended to check any PoV before using it."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " when orb is about to hit the ground and boss will be triggered. It's about 0.5-0.8 second."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Fully precast ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), " and attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), ". Swap your weapons during cast time."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you feel confident with timers precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), ", it should be precasted instantly after ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " when precast is executed properly."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> dodge to the LH to not get hit by death ray -> pick up LH -> ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " -> drop LH -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> cast ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " and jump over knocking wave during cast time while following boss -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " to boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If boss is still not phased continue with BTTH rota until it's phased."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Split 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC your add with ", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "You can use ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " to get back to center faster for p2."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Both in p2 and p3 try to precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " to get faster burst."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> autochain -> dodge deathwave -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Blast fire field with ", React.createElement(Skill, {
    id: "21656",
    id: "21656"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " as fast as possible"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Jump to boss with ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Don't use ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), ", save it for pre p3 blast."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue with BTTH rota if boss is still not phased."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Split 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Preferably you should CC middle add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Stay in ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " for blasts."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "CC the add with ", React.createElement(Skill, {
    id: "44998",
    id: "44998"
  }), "+", React.createElement(Skill, {
    id: "5687",
    id: "5687"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw your ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " for your ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " close to the middle."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> autochain -> dodge deathwave -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If boss is still not killed use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to get to the corner and just finish it with normal BTTH rota execution."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " In organized groups you should block/burn Rigorous Certainty on deathwave to not loose DPS by dodging it in middle."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "Arkk",
    video: "DaKI7Ccr_Ss",
    timestamp: "212",
    videoCreator: "Tym [dT]",
    foodId: "91805",
    utilityId: "50082",
    heal: "glyphofelementalharmony",
    utility1: "primordialstance",
    utility2: "glyphofstorms",
    utility3: "arcaneblast",
    elite: "conjurefierygreatsword",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "dagger",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    video: "DaKI7Ccr_Ss",
    videoCreator: "Tym [dT]",
    foodId: "91805",
    utilityId: "50082",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46760",
    weapon1OffSigilId: "24615",
    healId: "5569",
    utility1Id: "40183",
    utility2Id: "5734",
    utility3Id: "5539",
    eliteId: "5516"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for instant ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " at the start of each phase and mini bosses."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Spam your ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " during ", React.createElement(Effect, {
    name: "Exposed"
  }), " window. Use one blast at start of phase 1 and in phase 3 when ", React.createElement(Skill, {
    name: "onewolfpack",
    id: "45717"
  }), " is up."), "\n"), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5624",
    id: "5624"
  }), " before boss hitbox to pick it up during eye attack and turn back from boss when picking it up /gg or use portal to get back to ", React.createElement(_components.em, null, "Mistlock Singularity"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Equip a staff and cast ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " while attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and go through the portal for platform opener."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " You can use tempest precast as well if any party member is placing fire field."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Platform Opener")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5501",
    id: "5501"
  }), ", attune to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " and swap gear for Sw/D during ", React.createElement(Skill, {
    name: "Meteorshower",
    id: "5501"
  }), " cast."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " when boss is pulled and dark mist/cloud of arriving showed up ( you can wait till arkk shows up if you re not confident with timers )"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> pick up LH ( don't get ", React.createElement(Condition, {
    name: "Fear"
  }), "ed) -> ", React.createElement(Skill, {
    id: "5725",
    id: "5725"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If boss is still not phased continue with BTTH rota, start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " as fast as possible."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Orb pushing")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Wait until there is a connection between you and orb, dodge inside damaging field autoattack orb to push it towards pylon and use ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " to push it straight to the pylon. Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to come back to boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you are low on health and cannot push it that way stand in corner and wait til orb comes to you and push it with one precise autoattack"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Use healing skill to get health for push or to regain scholar uptime for p2."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Keep an eye on other orbs, try to precast ", React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " ( for blasts mostly )"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If group damage is low you can try to fill ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " in here."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Archdiviner")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " on mini boss as fast as possible"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Throw ", React.createElement(Skill, {
    id: "5516",
    id: "5516"
  }), " for your ", React.createElement(Specialization, {
    name: "Soulbeast"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "While CC bar is up use ", React.createElement(Skill, {
    id: "5697",
    id: "5697"
  }), " + ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " combo to cc the mini boss and provide some damage."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "NOTE:"), " Try to avoid corruption fields at any cost, they pretty hurts your phase 3."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5531",
    id: "5531"
  }), " and ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> auto chain ->", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Continue with BTTH rotation until boss is phased, try to not cancel anything while using ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " while avoiding marble attack."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 4")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Attune to ", React.createElement(Skill, {
    id: "5492",
    id: "5492"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), " and push orb. Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to come back to boss."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " ( for blasts )"), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Gladiator")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " and autoattack it while attuning back to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    id: "40183",
    id: "40183"
  }), " for ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " and additional damage."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 5")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start attuning to ", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  }), "/", React.createElement(Skill, {
    id: "5494",
    id: "5494"
  })), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 6")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Push your orb."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    id: "5737",
    id: "5737"
  }), " on boss"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    id: "43803",
    id: "43803"
  }), " -> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  }), " -> ", React.createElement(Skill, {
    id: "5529",
    id: "5529"
  }), " -> ", React.createElement(Skill, {
    id: "43074",
    id: "43074"
  }), " -> ", React.createElement(Skill, {
    id: "44451",
    id: "44451"
  }), " -> ", React.createElement(Skill, {
    id: "5691",
    id: "5691"
  }), " -> ", React.createElement(Skill, {
    id: "5557",
    id: "5557"
  }), "-> ", React.createElement(Skill, {
    id: "45313",
    id: "45313"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Try to save at least two ", React.createElement(Skill, {
    id: "5539",
    id: "5539"
  }), " for phase 6"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
