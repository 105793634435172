import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React from 'react';
import { BOSSES } from './Boss';
import { withGw2Theme } from '../../utils/withGw2Theme';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    border: `2px solid ${theme.palette.background.paper}`,
    '&:hover': {
      boxShadow: theme.shadows[16],
      border: `2px solid ${theme.palette.primary.main}`,
      '&> span': {
        background: theme.palette.primary.main,
        color: 'black',
      },
    },
  },
  anchor: {
    textDecoration: 'none',
  },
  caption: {
    textAlign: 'center',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem',
    },
    textTransform: 'uppercase',
    letterSpacing: 2,
    color: 'white',
    textShadow: '0 1px 0 black',
    background: theme.palette.background.paper,
    borderRadius: '10px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  imageContainer: {},
  image: {
    borderRadius: '4px',
  },
}));

function BossCard({ name, link }) {
  const { classes } = useStyles();
  const { Image } = BOSSES.find(
    (b) => b.name.toUpperCase() === name.toUpperCase(),
  );

  return (
    <div className={classes.root} onClick={() => scrollTo(link)}>
      <div className={classes.imageContainer}>
        <Image className={classes.image} />
      </div>
      <Typography variant="caption" className={classes.caption}>
        {name}
      </Typography>
    </div>
  );
}

export default withGw2Theme()(BossCard);
